import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    department: '',
    role: ''
  });
  const [message, setMessage] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch welcome message when the component mounts
    const fetchWelcomeMessage = async () => {
      try {
        const response = await axios.get('https://node.pglnigeriaerp.com/');
        console.log('Response:', response); // Log the entire response to see what is being returned
        setWelcomeMessage(response.data);
      } catch (error) {
        console.error('Error fetching welcome message:', error);
      }
    };

    fetchWelcomeMessage();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://node.pglnigeriaerp.com/register', formData);
      setMessage(response.data);
      setTimeout(() => {
        navigate('/login'); // Redirect to login page after successful registration
      }, 2000); // Adjust the timeout duration as needed
    } catch (error) {
      setErrorMessage('Error registering user. Please try again.');
    }
  };

  return (
    <>
      <h1>{welcomeMessage}</h1>
      <div className="form-div">
        <div className="container">
          <div className="card text-center">
            <div className="card-header" style={{ backgroundColor: '#000', color: "#fff" }}>
              PGL Task Master
            </div>
            <br />
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="department">Department:</label>
                <input
                  type="text"
                  id="department"
                  name="department"
                  value={formData.department}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="role">Role:</label>
                <input
                  type="text"
                  id="role"
                  name="role"
                  value={formData.role}
                  onChange={handleInputChange}
                  required
                />
              </div>
              {message && <p className="success">{message}</p>}
              {errorMessage && <p className="error">{errorMessage}</p>}
              <button type="submit">Register</button>
            </form>
            <br />
            <div className="card-footer" style={{ backgroundColor: '#000', color: "#fff" }}>
              InfoScripts Design
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
