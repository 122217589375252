import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Register from './komponents/Register';
import Login from './Login';
import Home from './Home';
import Tasks from './Tasks';
import Dash from './komponents/Dashboard';
import Proj from './komponents/Projects';
import NotFound from './NotFound';
import Navbar from './komponents/Navbar';
import Settings from './komponents/Settings';
import Sidebar from './komponents/SideBar';
import Footer from './komponents/Footer';
import Layout from './komponents/Layout';
import { UserProvider } from './komponents/UserContext';
import UserStatus from './komponents/UserStatus';
import TaskItem from './komponents/TaskItem';
import './App.css';
import Projects from './komponents/Projects';
import ClientView from './komponents/ClientItem';
import ProjectViews from './komponents/ProjectItem';
import ProjectTasks from './komponents/ProjectTask';
const App = () => {
  return (
    <div>
      <UserProvider>   
      <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register/>} />
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/dashboard" element={<Dash />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/projects" element={<Projects/>} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/taskItem/:id" element={<TaskItem/>} />
        <Route path="/clientItem/:id" element={<ClientView/>} />
        <Route path="/projectItem/:id" element={<ProjectViews/>} />
        <Route path="/projectItem/:projectId/milestones/:milestoneId/tasks/:taskId" element={<ProjectTasks />} />
    
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
      </UserProvider>
   
    </div>

  );
}

export default App;
