import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ProjectMod from './ProjectModal';
import ClientModal from './ClientModal';

const Proj = () => {
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showClientModal, setShowClientModal] = useState(false);
  const [currentClientPage, setCurrentClientPage] = useState(1);
  const [currentProjectPage, setCurrentProjectPage] = useState(1);
  const itemsPerPage = 6;

  useEffect(() => {
    fetchProjects();
    fetchClients();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get('https://node.pglnigeriaerp.com/projects');
      if (response.data) {
        setProjects(response.data);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get('https://node.pglnigeriaerp.com/clients');
      if (response.data) {
        setClients(response.data);
      } else {
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleDeleteClient = async (clientId) => {
    try {
      await axios.delete(`https://node.pglnigeriaerp.com/clients/${clientId}`);
      fetchClients();
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };


// Function to delete a project
const handleDeleteProject = async (projectId) => {
  if (window.confirm("Are you sure you want to delete this project?")) {
    try {
      await axios.delete(`https://node.pglnigeriaerp.com/projects/${projectId}`);
      fetchProjects(); // Refresh the projects list after deletion
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  }
};

  const indexOfLastClient = currentClientPage * itemsPerPage;
  const indexOfFirstClient = indexOfLastClient - itemsPerPage;
  const currentClients = clients.slice(indexOfFirstClient, indexOfLastClient);

  const indexOfLastProject = currentProjectPage * itemsPerPage;
  const indexOfFirstProject = indexOfLastProject - itemsPerPage;
  const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);

  const totalClientPages = Math.ceil(clients.length / itemsPerPage);
  const totalProjectPages = Math.ceil(projects.length / itemsPerPage);

  const handlePreviousClientPage = () => {
    setCurrentClientPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextClientPage = () => {
    setCurrentClientPage(prevPage => Math.min(prevPage + 1, totalClientPages));
  };

  const handlePreviousProjectPage = () => {
    setCurrentProjectPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextProjectPage = () => {
    setCurrentProjectPage(prevPage => Math.min(prevPage + 1, totalProjectPages));
  };

  const handleOpenProjectModal = () => setShowProjectModal(true);
  const handleCloseProjectModal = () => setShowProjectModal(false);
  
  const handleOpenClientModal = () => setShowClientModal(true);
  const handleCloseClientModal = () => setShowClientModal(false);

  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col">
          <div className="card" style={{ width: "12rem" }}>
            <div className="card-body">
              <h5 className="card-title">Project Card</h5>
              <h6 className="card-subtitle mb-2 text-muted">Project Count</h6>
              <button className="card-link btn btn-success">{projects.length}</button>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card" style={{ width: "12rem" }}>
            <div className="card-body">
              <h5 className="card-title">Client Card</h5>
              <h6 className="card-subtitle mb-2 text-muted">Client Count</h6>
              <button className="card-link btn btn-success">{clients.length}</button>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card" style={{ width: "12rem" }}>
            <div className="card-body">
              <h5 className="card-title">Client Card</h5>
              <h6 className="card-subtitle mb-2 text-muted">Create Client</h6>
              <button className="card-link btn btn-success" onClick={handleOpenClientModal}>Add</button>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card" style={{ width: "12rem" }}>
            <div className="card-body">
              <h5 className="card-title">Project Card</h5>
              <h6 className="card-subtitle mb-2 text-muted">Create Projects</h6>
              <button className="card-link btn btn-success" onClick={handleOpenProjectModal}>Add Projects</button>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3 className='banner' style={{background:"green"}}>Clients</h3>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentClients.map((client) => (
                <tr key={client.id}>
                  <th scope="row">{client.id}</th>
                  <td>{client.name}</td>
                  <td>{client.email}</td>
                  <td>{client.phone}</td>
                  <td>
                    <Link to={`/clientItem/${client.id}`} className="btn btn-success">
                      View
                    </Link>
                    {/* <button className="btn btn-danger" onClick={() => handleDeleteClient(client.id)}>Delete</button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="d-flex justify-content-between mb-5">
            <button 
              className="btn btn-primary" 
              onClick={handlePreviousClientPage} 
              disabled={currentClientPage === 1}
            >
              Previous
            </button>
            <span>Page {currentClientPage} of {totalClientPages}</span>
            <button 
              className="btn btn-primary" 
              onClick={handleNextClientPage} 
              disabled={currentClientPage === totalClientPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h3 className='banner' style={{background:"green"}}>Projects</h3>
          <div className="row">
            {currentProjects.map((project) => (
              <div className="col-md-4 mb-4" key={project.id}>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">{project.project_name}</h5>
                    <p className="card-text">{project.description}</p>
                    <Link to={`/projectItem/${project.id}`} className="btn btn-success">
                      View
                    </Link>
                    <button 
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDeleteProject(project.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-between mb-5">
            <button 
              className="btn btn-primary" 
              onClick={handlePreviousProjectPage} 
              disabled={currentProjectPage === 1}
            >
              Previous
            </button>
            <span>Page {currentProjectPage} of {totalProjectPages}</span>
            <button 
              className="btn btn-primary" 
              onClick={handleNextProjectPage} 
              disabled={currentProjectPage === totalProjectPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      
      <ProjectMod 
        showModal={showProjectModal} 
        handleCloseModal={handleCloseProjectModal} 
        fetchProjects={fetchProjects}
      />

      <ClientModal 
        showModal={showClientModal} 
        handleCloseModal={handleCloseClientModal} 
        fetchClients={fetchClients}
      />
    </div>
  );
};

export default Proj;
