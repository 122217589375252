import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TaskModal from './TaskModal';

const TaskMeter = ({ tasks, onTaskCreate }) => {
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [projectModalVisible, setProjectModalVisible] = useState(false);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedMilestone, setSelectedMilestone] = useState('');
  const [assignedUser, setAssignedUser] = useState('');
  const [taskName, setTaskName] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchProjectsAndUsers = async () => {
      try {
        const projectsResponse = await axios.get('https://node.pglnigeriaerp.com/projects');
        const usersResponse = await axios.get('https://node.pglnigeriaerp.com/users');
        
        console.log('Projects Response:', projectsResponse);
        console.log('Users Response:', usersResponse);

        if (projectsResponse.data && usersResponse.data) {
          setProjects(projectsResponse.data || []);
          setUsers(usersResponse.data || []);
        } else {
          console.error('Unexpected response structure for projects or users:', projectsResponse, usersResponse);
        }
      } catch (error) {
        console.error('Error fetching projects or users:', error);
      }
    };

    fetchProjectsAndUsers();
  }, []);

  useEffect(() => {
    const fetchMilestones = async () => {
      if (selectedProject) {
        try {
          const response = await axios.get(`https://node.pglnigeriaerp.com/projects/${selectedProject}/milestones`);
          
          console.log('Milestones Response:', response);

          if (Array.isArray(response.data)) {
            setMilestones(response.data);
          } else {
            console.error('Unexpected response structure for milestones:', response);
            setMilestones([]);
          }
        } catch (error) {
          console.error('Error fetching milestones:', error);
          setMilestones([]);
        }
      } else {
        setMilestones([]);
      }
    };

    fetchMilestones();
  }, [selectedProject]);

  const handleCreateTask = async (taskData) => {
    try {
      await onTaskCreate(taskData);
      setTaskModalVisible(false);
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  const handleCreateProjectTask = async (e) => {
    e.preventDefault();

    const taskData = {
      title: taskName,
      description,
      assigned_to: assignedUser,
      project_id: selectedProject,
      milestone_id: selectedMilestone
    };

    try {
      const url = `https://node.pglnigeriaerp.com/projects/${selectedProject}/milestones/${selectedMilestone}/tasks`;
      await axios.post(url, taskData);
      console.log('Project task created:', taskData);
      setProjectModalVisible(false);
    } catch (error) {
      console.error('Error creating project task:', error);
    }
  };

  return (
    <section className="task-meter">
      <div className="task-card">
        <div className="card-body">
          <h5 className="card-title">Task Counter</h5>
          <span className="card-text btn btn-primary">{tasks.length}</span>
        </div>
      </div>

      <div className="task-card">
        <div className="card-body">
          <h5 className="card-title">Add Task</h5>
          <button className="btn btn-primary" onClick={() => setTaskModalVisible(true)}>
            <i className="fas fa-plus"></i>
          </button>
        </div>
      </div>

      <div className="task-card">
        <div className="card-body">
          <h5 className="card-title">Add Project Task</h5>
          <button className="btn btn-primary" onClick={() => setProjectModalVisible(true)}>
            <i className="fas fa-plus"></i>
          </button>
        </div>
      </div>

      {taskModalVisible && (
        <TaskModal onClose={() => setTaskModalVisible(false)} onSubmit={handleCreateTask} />
      )}

      {projectModalVisible && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <span style={closeButtonStyle} onClick={() => setProjectModalVisible(false)}>
              &times;
            </span>
            <h2>Select Project, Milestone, Assign User, and Add Task Details</h2>
            <form onSubmit={handleCreateProjectTask} style={{marginBottom:"114px"}}>
              <div style={formGroupStyle}>
                <label>
                  Project:
                  <select
                    value={selectedProject}
                    onChange={(e) => setSelectedProject(e.target.value)}
                    style={selectStyle}
                  >
                    <option value="">Select a project</option>
                    {projects.map((project) => (
                      <option key={project.id} value={project.id}>
                        {project.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              {selectedProject && (
                <>
                  <div style={formGroupStyle}>
                    <label>
                      Milestone:
                      <select
                        value={selectedMilestone}
                        onChange={(e) => setSelectedMilestone(e.target.value)}
                        style={selectStyle}
                      >
                        <option value="">Select a milestone</option>
                        {milestones.map((milestone) => (
                          <option key={milestone.id} value={milestone.id}>
                            {milestone.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>

                  <div style={formGroupStyle}>
                    <label>
                      Assigned User:
                      <select
                        value={assignedUser}
                        onChange={(e) => setAssignedUser(e.target.value)}
                        style={selectStyle}
                      >
                        <option value="">Select a user</option>
                        {users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>
                </>
              )}

              <div style={formGroupStyle}>
                <label>
                  Task Name:
                  <input
                    type="text"
                    value={taskName}
                    onChange={(e) => setTaskName(e.target.value)}
                    style={inputStyle}
                  />
                </label>
              </div>

              <div style={formGroupStyle}>
                <label>
                  Description:
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={textareaStyle}
                  />
                </label>
              </div>

              <button type="submit" style={submitButtonStyle}>Submit</button>
            </form>
          </div>
        </div>
      )}
    </section>
  );
};

// Inline styles for the modal and form elements
const modalStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '1000',
};

const modalContentStyle = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '5px',
  width: '300px',
  textAlign: 'center',
  position: 'relative',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  fontSize: '20px',
  cursor: 'pointer',
};

const formGroupStyle = {
  marginBottom: '15px',
};

const selectStyle = {
  width: '100%',
  padding: '8px',
  marginTop: '5px',
  boxSizing: 'border-box',
};

const inputStyle = {
  width: '100%',
  padding: '8px',
  boxSizing: 'border-box',
};

const textareaStyle = {
  width: '100%',
  padding: '8px',
  boxSizing: 'border-box',
  minHeight: '80px',
};

const submitButtonStyle = {
  backgroundColor: '#007bff',
  color: 'white',
  border: 'none',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
  marginTop: '10px',
};

export default TaskMeter;
