// Home.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import './styles/Home.css';
const Home = () => {
  const location = useLocation();
  const [username, setUsername] = useState(location.state?.username || '');
  const [role, setRole] = useState(location.state?.role || '');
  const [department, setDepartment] = useState(location.state?.department || '');
  useEffect(() => {
    if (!username) {
      const token = Cookies.get('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        setUsername(decodedToken.username);
        setRole(decodedToken.role);
        setDepartment(decodedToken.department);
      }
    }
  }, [username]);

  return (

    <div className="home-container">
      <div className="welcome-section">
        <h1>Welcome to PGL Task Master, {username}!</h1>
        <p>Your role: {role}</p>
        <p>Your department: {department}</p>
        <p>Your ultimate task management solution</p>
        <button className="button">Get Started</button>
      </div>
      <br />
      <div className="section quotes-section">
        <h3>Inspirational Quotes</h3>
        <p>"The best way to predict the future is to create it." - Peter Drucker</p>
      </div>
      <div className="section news-section">
        <h3>News</h3>
        <p>Latest news will be displayed here.</p>
      </div>
      <div className="section affairs-section">
        <h3>Company Affairs</h3>
        <p>Information about company affairs will be displayed here.</p>
      </div>
      <div className="section announcements-section">
        <h3>Announcements</h3>
        <p>Latest announcements will be displayed here.</p>
      </div>
      <div className="section financial-section">
        <h3>Financial Updates</h3>
        <p>Financial updates will be displayed here.</p>
      </div>
    </div>
  );
};

export default Home;
