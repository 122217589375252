import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import Sidebar from './SideBar';
import Footer from './Footer';
import '../styles/Layout.css'
 // Make sure to import your CSS file

const Layout = () => {
  return (
   <div className="sb-nav-fixed">
      <Navbar />
          <div id="layoutSidenav">
          <Sidebar />
              <div id="layoutSidenav_content">
              <Outlet />
              </div>

          </div>
        <Footer />
</div>
  );
};

export default Layout;
