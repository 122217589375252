import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const ClientView = () => {
  const { id } = useParams();
  const [client, setClient] = useState(null);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await axios.get(`https://node.pglnigeriaerp.com/clients/${id}`);
        setClient(response.data);
      } catch (error) {
        console.error('Error fetching client:', error);
      }
    };

    fetchClient();
  }, [id]);

  if (!client) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <h3>Client Details</h3>
      <table className="table">
        <tbody>
          <tr>
            <th>Company</th>
            <td>{client.company}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{client.name}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{client.email}</td>
          </tr>
          <tr>
            <th>Phone</th>
            <td>{client.phone}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ClientView;
