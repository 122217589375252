// Dashboard.js
import React from 'react';


const Dashboard = () => {
  return (
    <div className="container-fluid">
            <div>
             <h1> COMING SOON</h1>

            </div>
    </div>
  );
};

export default Dashboard;
