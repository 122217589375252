import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";

const ProjectViews = () => {
  const [project, setProject] = useState(null);
  const [milestones, setMilestones] = useState([]);
  const [tasks, setTasks] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [milestoneData, setMilestoneData] = useState({
    name: '',
    description: '',
    due_date: '',
    status: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [milestonesPerPage] = useState(6);
  const [currentMilestoneTasks, setCurrentMilestoneTasks] = useState(null);
  const { id: projectId } = useParams();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(`https://node.pglnigeriaerp.com/projects/${projectId}`);
        console.log('Project Response:', response.data);
        setProject(response.data);
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    };

    const fetchMilestones = async () => {
      try {
        const response = await axios.get(`https://node.pglnigeriaerp.com/projects/${projectId}/milestones`);
        console.log('Milestones Response:', response.data);
        setMilestones(response.data);
        
        // Fetch tasks for each milestone
        const tasksFetches = response.data.map(milestone =>
          axios.get(`https://node.pglnigeriaerp.com/projects/${projectId}/milestones/${milestone.id}/tasks`)
            .then(tasksResponse => ({
              milestoneId: milestone.id,
              tasks: tasksResponse.data.tasks
            }))
        );
        const tasksResults = await Promise.all(tasksFetches);
        const tasksMap = tasksResults.reduce((acc, { milestoneId, tasks }) => {
          acc[milestoneId] = tasks;
          return acc;
        }, {});
        setTasks(tasksMap);
      } catch (error) {
        console.error('Error fetching milestones:', error);
        setMilestones([]);
      }
    };

    fetchProject();
    fetchMilestones();
  }, [projectId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMilestoneData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`https://node.pglnigeriaerp.com/projects/${projectId}/milestones`, {
        ...milestoneData,
        project_id: projectId,
      });
      setMilestoneData({
        name: '',
        description: '',
        due_date: '',
        status: '',
      });
      setShowModal(false);
      const response = await axios.get(`https://node.pglnigeriaerp.com/projects/${projectId}/milestones`);
      console.log('Milestones Response after Adding:', response.data);
      setMilestones(response.data);
      
      // Fetch tasks for each milestone
      const tasksFetches = response.data.map(milestone =>
        axios.get(`https://node.pglnigeriaerp.com/projects/${projectId}/milestones/${milestone.id}/tasks`)
          .then(tasksResponse => ({
            milestoneId: milestone.id,
            tasks: tasksResponse.data.tasks
          }))
      );
      const tasksResults = await Promise.all(tasksFetches);
      const tasksMap = tasksResults.reduce((acc, { milestoneId, tasks }) => {
        acc[milestoneId] = tasks;
        return acc;
      }, {});
      setTasks(tasksMap);
    } catch (error) {
      console.error('Error creating milestone:', error);
    }
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  if (!project) return <div>Loading...</div>;

  // Pagination logic
  const indexOfLastMilestone = currentPage * milestonesPerPage;
  const indexOfFirstMilestone = indexOfLastMilestone - milestonesPerPage;
  const currentMilestones = milestones.slice(indexOfFirstMilestone, indexOfLastMilestone);
  const totalPages = Math.ceil(milestones.length / milestonesPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const displayTasks = (milestoneId) => {
    if (currentMilestoneTasks && currentMilestoneTasks.milestoneId === milestoneId) {
      setCurrentMilestoneTasks(null);
    } else {
      setCurrentMilestoneTasks({ milestoneId, tasks: tasks[milestoneId] });
    }
  };

  return (
    <div className="container">
      <h3 className="banner">Project Details</h3>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col">Created At</th>
            <th scope="col">Updated At</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">{project.id}</th>
            <td>{project.name}</td>
            <td>{project.description}</td>
            <td>{new Date(project.created_at).toLocaleString()}</td>
            <td>{new Date(project.updated_at).toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
      <button type="button" className="btn btn-success" onClick={handleOpenModal}>Add Milestone</button>

      {showModal && (
        <div className="modal fade show" style={{ display: 'block' }} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Add Milestone</h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="milestoneName" className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="milestoneName"
                      name="name"
                      value={milestoneData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="milestoneDescription" className="form-label">Description</label>
                    <textarea
                      className="form-control"
                      id="milestoneDescription"
                      name="description"
                      value={milestoneData.description}
                      onChange={handleInputChange}
                      required
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="milestoneDueDate" className="form-label">Due Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="milestoneDueDate"
                      name="due_date"
                      value={milestoneData.due_date}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="milestoneStatus" className="form-label">Status</label>
                    <select
                      className="form-select"
                      id="milestoneStatus"
                      name="status"
                      value={milestoneData.status}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select status</option>
                      <option value="not-started">Not Started</option>
                      <option value="in-progress">In Progress</option>
                      <option value="completed">Completed</option>
                      <option value="pending">Pending</option>
                    </select>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                  <button type="submit" className="btn btn-primary">Add Milestone</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="mt-5">
        <h4 className="banner">Milestones</h4>
        <ul className="list-group">
          {currentMilestones.length > 0 ? (
            currentMilestones.map((milestone) => (
              <li key={milestone.id} className="list-group-item" style={{ marginBottom: "9px" }}>
                <h5>{milestone.name}</h5>
                <p>{milestone.description}</p>
                <p>Due Date: {new Date(milestone.due_date).toLocaleDateString()}</p>
                <p>Status: {milestone.status}</p>
                <button onClick={() => displayTasks(milestone.id)} className="btn btn-primary">
                  {currentMilestoneTasks && currentMilestoneTasks.milestoneId === milestone.id ? 'Hide Tasks' : 'View Tasks'}
                </button>

                {currentMilestoneTasks && currentMilestoneTasks.milestoneId === milestone.id && (
                  <table className="table mt-3">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Title</th>
                        <th scope="col">Description</th>
                        <th scope="col">Assigned To</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentMilestoneTasks.tasks.map((task) => (
                        <tr key={task.id}>
                          <th scope="row">{task.id}</th>
                          <td>{task.title}</td>
                          <td>{task.description}</td>
                          <td>{task.assigned_to}</td>
                          <td>
                            <Link to={`/projectItem/${project.id}/milestones/${milestone.id}/tasks/${task.id}`} className="btn btn-sm btn-primary">View</Link>
                            <button className="btn btn-sm btn-danger ms-2">Delete</button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </li>
            ))
          ) : (
            <li className="list-group-item">No milestones available.</li>
          )}
        </ul>

        <nav>
          <ul className="pagination mt-4">
            {Array.from({ length: totalPages }, (_, index) => (
              <li key={index + 1} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default ProjectViews;
