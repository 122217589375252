// src/components/Tasks.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TaskMeter from "./komponents/TaskMeter";
import TaskTable from './komponents/TaskTable';
const Tasks = () => {
  const [tasks, setTasks] = useState([]);
  const [milestoneTasks, setMilestoneTasks] = useState([]);
  const [users,setUsers] = useState([]);
  useEffect(() => {
    fetchTasks();
    fetchUsers();
    fetchMilestoneTasks(); 
  }, []);

  const fetchTasks = async () => {
    try {
      const response = await axios.get('https://node.pglnigeriaerp.com/tasks');
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };


  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://node.pglnigeriaerp.com/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchMilestoneTasks = async () => {
    try {
      const response = await axios.get('https://node.pglnigeriaerp.com/projects/{id}/milestones/{id}/tasks');
      setMilestoneTasks(response.data.tasks || []);
    } catch (error) {
      console.error('Error fetching milestone tasks:', error);
    }
  };

  const handleTaskCreate = async (taskData) => {
    try {
      const response = await axios.post('https://node.pglnigeriaerp.com/tasks', taskData);
      setTasks(prevTasks => [...prevTasks, response.data]);
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };


  const handleDeleteTask = async (taskId) => {
    try {
      await axios.delete(`https://node.pglnigeriaerp.com/tasks/${taskId}`);
      setTasks(tasks.filter(task => task.id !== taskId));
      setMilestoneTasks(milestoneTasks.filter(task => task.id !== taskId)); // Remove from milestone tasks too
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const getUserNameById = (userId) => {
    const user = users.find(user => user.id === userId);
    return user ? user.name : 'N/A';
  };

  return (
    <div style={{ padding: "25px" }}>
        <TaskMeter tasks={tasks} onTaskCreate={handleTaskCreate} />
      <h1 className="banner" style={{background:"green"}}>Task Table</h1>

      <TaskTable tasks={tasks}   users={users} onDeleteTask={handleDeleteTask}/>

      
    </div>
  );
};

export default Tasks;
