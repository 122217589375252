import React, { useState } from 'react';
import axios from 'axios';

const ClientModal = ({ showModal, handleCloseModal, fetchClients }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [company, setCompany] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://node.pglnigeriaerp.com/clients', { name, email, phone, address, company });
      fetchClients();
      handleCloseModal();
    } catch (error) {
      setError('Error creating client');
      console.error('Error creating client:', error);
    }
  };

  if (!showModal) return null;

  return (
    <div className="modal show d-block">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Client</h5>
            <button type="button" className="close" onClick={handleCloseModal}>
              <span>&times;</span>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="clientName">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="clientName"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="clientEmail">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="clientEmail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="clientPhone">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  id="clientPhone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="clientAddress">Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="clientAddress"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="clientCompany">Company</label>
                <input
                  type="text"
                  className="form-control"
                  id="clientCompany"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>
                Close
              </button>
              <button type="submit" className="btn btn-primary">Save Client</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClientModal;
