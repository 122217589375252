// Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles/login.css';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log('Form data:', formData); // Log the form data for debugging
    try {
      const response = await axios.post('https://node.pglnigeriaerp.com/login', formData);
      if (response.status === 200) {
        navigate('/home'); // Navigate to the home page on successful login
      } else {
        setErrorMessage(response.data || 'Login failed');
      }
    } catch (error) {
      console.error('Failed to login:', error);
      if (error.response) {
        // Server responded with a status other than 2xx
        setErrorMessage(error.response.data || 'Error logging in');
      } else if (error.request) {
        // Request was made but no response was received
        setErrorMessage('No response from server. Please try again later.');
      } else {
        // Something else happened in setting up the request
        setErrorMessage('Error logging in. Please try again.');
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className="form-div">
      <div className="container">
        <div className="card text-center">
          <div className="card-header" style={{ backgroundColor: '#000', color: "#fff" }}>
            PGL Task Master
          </div>
          <br />
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                required
              />
            </div>
            {errorMessage && <p className="error">{errorMessage}</p>}
            <button type="submit">Login</button>
          </form>
          <br />
          <div className="card-footer" style={{ backgroundColor: '#000', color: "#fff" }}>
            InfoScripts Design
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
