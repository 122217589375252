import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const ProjectTasks = () => {
  const { projectId, milestoneId, taskId } = useParams();
  const [task, setTask] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const response = await axios.get(`https://node.pglnigeriaerp.com/projects/${projectId}/milestones/${milestoneId}/tasks/${taskId}`);
        setTask(response.data);
        setIsLoading(false);
      } catch (error) {
        setError("Error fetching task details.");
        console.error('Error fetching task:', error);
        setIsLoading(false);
      }
    };

    fetchTask();
  }, [projectId, milestoneId, taskId]);

  const handleStageChange = async (newStage) => {
    try {
      await axios.put(`https://node.pglnigeriaerp.com/projects/${projectId}/milestones/${milestoneId}/tasks/${taskId}`, { stage: newStage });
      setTask((prevTask) => ({
        ...prevTask,
        stage: newStage,
      }));
    } catch (error) {
      setError("Error updating task stage.");
      console.error('Error updating stage:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h1>Task Details</h1>
      <table className="table">
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ID</td>
            <td>{task.id}</td>
          </tr>
          <tr>
            <td>Title</td>
            <td>{task.title}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{task.description}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{task.status}</td>
          </tr>
          <tr>
            <td>Priority</td>
            <td>{task.priority}</td>
          </tr>
          <tr>
            <td>Stage</td>
            <td>
              <div>
                <label>
                  <input
                    type="radio"
                    name="stage"
                    value="To Do"
                    checked={task.stage === "To Do"}
                    onChange={() => handleStageChange("To Do")}
                  />
                  To Do
                </label>
                <label>
                  <input
                    type="radio"
                    name="stage"
                    value="In Progress"
                    checked={task.stage === "In Progress"}
                    onChange={() => handleStageChange("In Progress")}
                  />
                  In Progress
                </label>
                <label>
                  <input
                    type="radio"
                    name="stage"
                    value="Review"
                    checked={task.stage === "Review"}
                    onChange={() => handleStageChange("Review")}
                  />
                  Review
                </label>
                <label>
                  <input
                    type="radio"
                    name="stage"
                    value="Done"
                    checked={task.stage === "Done"}
                    onChange={() => handleStageChange("Done")}
                  />
                  Done
                </label>
              </div>
            </td>
          </tr>
          {/* Uncomment if needed */}
          {/* <tr>
            <td>Created At</td>
            <td>{new Date(task.created_at).toLocaleString()}</td>
          </tr>
          <tr>
            <td>Updated At</td>
            <td>{new Date(task.updated_at).toLocaleString()}</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectTasks;
