// src/components/TaskItem.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const TaskItem = () => {
  const { id } = useParams(); // Extract the task ID from the route parameters
  const [task, setTask] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTask();
  }, [id]);

  const fetchTask = async () => {
    try {
      const response = await axios.get(`https://node.pglnigeriaerp.com/tasks/${id}`);
      setTask(response.data);
      fetchUser(response.data.assigned_to); // Fetch the assigned user based on the ID
    } catch (error) {
      console.error('Error fetching task:', error);
      setError('Failed to load task');
      setLoading(false);
    }
  };

  const fetchUser = async (userId) => {
    try {
      const response = await axios.get(`https://node.pglnigeriaerp.com/users/${userId}`);
      setAssignedUser(response.data);
    } catch (error) {
      console.error('Error fetching user:', error);
      setError('Failed to load user');
    } finally {
      setLoading(false);
    }
  };

  const handleStageChange = async (event) => {
    const newStage = event.target.value;
    try {
      await axios.put(`https://node.pglnigeriaerp.com/tasks/${id}/stage`, { stage: newStage });
      setTask({ ...task, stage: newStage });
    } catch (error) {
      console.error('Error updating stage:', error);
      setError('Failed to update stage');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const stages = ["To Do", "In Progress", "Review", "Done"]; // Define the stages array

  return (
    <div className='container-fluid'>
      <h2 className='banner' style={{ marginTop: "42px", marginBottom: "42px", padding: "12px" }}>Task Appraisal</h2>
      <table className="table table-striped" border="1" style={{ width: '100%', margin: '20px 0' }}>
        <tbody>
          <tr>
            <td style={{ fontWeight: 'bold' }}>Title</td>
            <td>{task.title}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold' }}>Description</td>
            <td>{task.description}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold' }}>Status</td>
            <td>{task.status}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold' }}>Assigned To</td>
            <td>{assignedUser ? assignedUser.name : 'Unknown User'}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: 'bold' }}>Stages</td>
            <td>
              <div style={{ display: 'flex', gap: '10px' }}>
                {stages.map(stage => (
                  <div className="form-check" key={stage} style={{ display: 'inline-block', transition: 'transform 0.3s' }}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="stage"
                      id={stage}
                      value={stage}
                      checked={task.stage === stage}
                      onChange={handleStageChange}
                      style={{ cursor: 'pointer' }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={stage}
                      style={{ marginLeft: '5px', transition: 'color 0.3s' }}
                    >
                      {stage}
                    </label>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TaskItem;
