import React, { useState } from "react";
import { Link } from "react-router-dom";
const TaskTable = ({ tasks, users,onDeleteTask }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const taskPerPage = 6;

  const indexOfLastTask = currentPage * taskPerPage;
  const indexOfFirstTask = indexOfLastTask - taskPerPage;
  const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);
  const totalPages = Math.ceil(tasks.length / taskPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getUserName = (userId) => {
    const user = users.find((user) => user.id === userId);
    return user ? user.name : "Unknown User";
  };

  return (
    <>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Task</th>
            <th scope="col">Status</th>
            <th scope="col">Assigned To</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentTasks.map((task, index) => (
            <tr key={task.id}>
              <th scope="row">{indexOfFirstTask + index + 1}</th>
              <td>{task.title}</td>
              <td>{task.stage}</td>
              <td>{getUserName(task.assigned_to)}</td>
              <td>
              <Link to={`/taskItem/${task.id}`} className="btn btn-info">View</Link>
                <button className="btn btn-danger btn-sm" onClick={() => onDeleteTask(task.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handleClick(index + 1)}
            className={`btn ${currentPage === index + 1 ? "btn-primary" : "btn-secondary"} mx-1`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </>
  );
};

export default TaskTable;
