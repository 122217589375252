// UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkUser = () => {
      const token = Cookies.get('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        setUser({ username: decodedToken.username });
      } else {
        setUser(null);
      }
    };

    checkUser();

    const interval = setInterval(checkUser, 5000); // Check every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <UserContext.Provider value={{ user }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
